import { OrderActionModal } from "../../components/common/template/orderActionModal";

export const flagOrderAction = (recordID: number) => ({
  variant: "modal",
  title: "Flag Order",
  modalTemplate: OrderActionModal,
  modalProps: {
    recordID,
    header: "Flag Order",
    variant: "flag",
    saveButtonLabel: "Flag this Order",
    url: "api:internal-orders-flag",
    hasDispositionForm: true,
  },
});
