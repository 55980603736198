import { LuAlertCircle } from "react-icons/lu";

import { CheckBox } from "@/components/CheckBox";
import { Buttons } from "./components/Buttons";
import { TextBox } from "./components/TextBox";
import { Input } from "@root/src/components/Input";
import { MailerInput } from "./components/mailerInput";
import { Review } from "./components/Review";
import { Alert } from "@/components/Alert";
import { useMailerLogic } from "./hooks/useMailerLogic";
import { MailerModalProps } from "./types";
import { isUserMailerArray } from "./utils/isUserMailerArray";

import "./scss/index.scss";

export const MailerModal = ({
  onCloseModal,
  ...mailerProps
}: MailerModalProps) => {
  const {
    review,
    mailerData,
    reviewHandler,
    sendCopy,
    handleSendCopy,
    submitEmail,
    currentUser,
    displayError,
    errorMessage,
    handleMailerData,
    handleDisplayError,
    submitReview,
    getBccContent,
    mailerState,
    bccHelperText,
    currentUserEmail,
    handleBccOnError,
    mailerDataMap,
    recipientDetails,
    isCurrentUserSuperUser,
    ccUrl,
    getMailerDataMap,
    recipient,
    ccRecipientDetails,
  } = useMailerLogic({ ...mailerProps, onCloseModal });

  if (review)
    return (
      <Review
        {...{
          review,
          mailerData,
          currentUserEmail,
          reviewHandler,
          sendCopy,
          handleSendCopy,
          isCurrentUserSuperUser,
          onCloseModal,
          submitEmail,
          bccContent: getBccContent(recipient),
        }}
      />
    );

  return (
    <div className="custom-mailer-container">
      <div className="custom-mailer-header">Compose Message</div>
      <Input
        {...{
          key: currentUser,
          label: "From",
          disabled: true, //Inactive by default
          value: currentUser,
        }}
      />

      {displayError ? (
        <Alert
          icon={LuAlertCircle}
          description={errorMessage}
          color={"destructive"}
          closeButton
        />
      ) : null}

      {isCurrentUserSuperUser ? (
        <MailerInput<"user">
          {...{
            label: "CC",
            url: ccUrl,
            onChange: (superUsers) => handleMailerData("cc", superUsers),
            recipientDetails: ccRecipientDetails,
            handleDisplayError,
            initialValues: getMailerDataMap("cc"),
            helperText: bccHelperText,
          }}
        />
      ) : null}

      <MailerInput
        {...{
          label: "BCC",
          url: recipientDetails.suggestionsUrl,
          onChange: (selectedEmails) => {
            handleDisplayError(false); //Reset the error
            return mailerDataMap(recipient, selectedEmails); //update mailer data
          },
          recipientDetails,
          autoFocus: true,
          onFilter: (selectedEmails) => {
            return isUserMailerArray(selectedEmails) //If the selectedEmails are of type  users
              ? selectedEmails.filter(({ is_superuser }) => !is_superuser) //Remove super users
              : selectedEmails;
          },
          initialValues: getMailerDataMap(recipient),
          helperText: "You can add multiple users",
          onError: handleBccOnError,
        }}
      />
      <Input
        {...{
          label: "Subject",
          onChange: (value) => handleMailerData("subject", value),
          value: mailerData.subject,
        }}
      />
      <TextBox
        {...{
          label: "Compose",
          message: mailerData.message,
          onChange: (value) => handleMailerData("message", value),
          value: mailerData.message,
        }}
      />
      <CheckBox
        onClick={handleSendCopy}
        label="Send me a copy of this email for reference"
        checked={sendCopy}
      />
      <Buttons
        {...{
          submitReview,
          ...mailerState,
          onCloseModal,
        }}
      />
    </div>
  );
};
