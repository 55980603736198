import { DropdownLinkItemProps } from "./types";
import "./scss/index.scss";

export const DropdownLinkItem = ({
  title,
  href = "#",
  icon: Icon,
  rightAlignment,
  data_url,
  data_id,
  onClick: handleOnClick,
  className,
  data_source_profile_pk,
}: DropdownLinkItemProps) => (
  <a
    className={`dropdown-menu-link-item ${
      rightAlignment ? "right-alignment" : ""
    } ${className ? className : ""}`}
    data-url={data_url}
    data-source_profile_pk={data_source_profile_pk}
    data-id={data_id}
    onClick={() => handleOnClick?.({ value: title.toString(), title })}
    href={href}
  >
    {Icon ? <Icon size={15} /> : null}
    <span>{title}</span>
  </a>
);
