import { FC } from "react";

import { getURL } from "@/utils/getURL";
import { Table } from "@/components/Table";
import { UserAdministrationType } from "./types/userAdministration";
import { useModal } from "@/components/Table/hooks/useModal";
import { GreenCircle } from "./assets/greenCircle";
import { GrayCircle } from "./assets/grayCircle";
import { MailerModal } from "@/components/Table/components/BaseTable/hooks/useTableRows/components/TableRowItem/components/TableRowActions/components/GenerateActions/utils/useGetAction/components/Email/template/MailerModal";
import { TableHeaders } from "@/components/Table/types/TableHeaders";

import "./scss/index.scss";

export const UserAdministration: FC = () => {
  const headers: TableHeaders = [
    {
      title: "NAME",
      key: "full_name",
    },
    { title: "EMAIL", key: "email" },
    { title: "FACILITY", key: ["facility", "name"] },
    {
      title: "ROLE",
      key: "role",
      type: "badge",
      properties: {
        badgeColor: {
          "group-admin": "success",
          "tech": "neutral",
          "admin": "neutral",
        },
      },
    },
    {
      title: "ACTIVE",
      key: "is_active",
      type: "boolean",
      properties: {
        booleanType: {
          true: <GreenCircle />,
          false: <GrayCircle />,
        },
      },
    },
    {
      title: "PERMISSIONS",
      key: "permission_groups",
      type: "array",
      properties: { key: "short_name" },
    },
    {
      title: "LAST ACTIVITY",
      key: "last_activity_date",
      type: "date"
    },
  ];

  const url: string = getURL("api:internal-users-list");
  const tableOptionsUrl: string = getURL("api:internal-users-schema-options");
  const { modal, handleOpenModal } = useModal(MailerModal);

  return (
    <>
      {modal}
      <Table<UserAdministrationType>
        bulkActions={{
          primaryAction: {
            label: "Email",
            onClick: handleOpenModal,
            config: {
              type: "user",
            },
          },
        }}
        actions={[
          "impersonate",
          "edit_user_permissions",
          {
            "current": "email",
            config: {
              type: "user",
            },
          },
        ]}
        id={"user_id"}
        name="All Users"
        //Todo: https://bloodbuy.atlassian.net/browse/BB-4970
        // tooltipHelper={{
        //   variant: "?",
        //   message: "Tooltip message TODO",
        // }}
        searchable
        options={{ url: tableOptionsUrl }}
        headers={headers}
        url={url}
      />
    </>
  );
};
