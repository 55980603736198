import { useState } from "react";
import { ModalTemplateProps } from "@/components/Table/hooks/useModal/types";
import { DropdownSingleSelect } from "@/components/DropdownSingleSelect";
import { OrderActionModalProps } from "./types";
import { useSelectedOption } from "./hooks/useSelectedOption";
import { OrderHeader } from "./components/OrderHeader";
import { OrderDispositionForm } from "./components/OrderDispositionForm";
import { OrderTextArea } from "./components/OrderTextArea";
import { OrderButtons } from "./components/OrderButtons";
import { usePostOrderAction } from "../../../../api/usePostOrderAction";
import { debounce } from "@/utils/debounce";
import { useGetOptions } from "../../../../api/useGetOptions";
import { Input } from "@root/src/components/Input";
import "./scss/index.scss";

export const OrderActionModal = ({
  recordID: orderID,
  variant,
  header,
  url,
  ...props
}: ModalTemplateProps<OrderActionModalProps>) => {
  // Fetch selectable order options
  const options = useGetOptions(url, orderID);

  // Handler to post order actions
  const handleOnClick = usePostOrderAction(url);

  // User input state for validation (e.g., typing "CANCEL")
  const [input, setInput] = useState<string>("");

  // Track the selected reason and handler
  const { selectedOption, handleSelectedOption } = useSelectedOption();

  /* Validation logic for disabling primary button*/
  // Required keyword to confirm cancel action
  const wordToMatch = "CANCEL";
  const isMatch = input === wordToMatch;

  // Validate selected reason and optional note
  const hasSelectedReason = selectedOption.reason !== null;
  const hasNote = selectedOption.notes.trim() !== "";

  // Check if user selected the custom/multi reason option
  const lastOption: number | undefined = options.at(-1)?.value;
  const isMultiReason: boolean = selectedOption.reason === lastOption;

  // Disable button unless all conditions are met
  const isPrimaryDisabled =
    !hasSelectedReason ||
    (isMultiReason && !hasNote) ||
    (variant === "cancel" && !isMatch);

  return (
    <div className="orders-actions-modal-container">
      <OrderHeader {...{ header, orderID }} />
      <DropdownSingleSelect<number>
        options={options}
        label="Reason"
        selectedOption={selectedOption.reason as number}
        size="large"
        onClick={({ value }) => handleSelectedOption("reason", value as number)}
      />
      {variant === "flag" ? <OrderDispositionForm /> : null}
      <OrderTextArea
        {...{
          isMultiReason,
          handleSelectedOption: debounce(handleSelectedOption, 500),
        }}
      />
      {variant === "cancel" ? (
        <Input
          value={input}
          onChange={setInput}
          label={`Type '${wordToMatch}' to confirm`}
        />
      ) : null}
      <OrderButtons
        {...{
          ...props,
          onClick: handleOnClick,
          isMultiReason,
          isPrimaryDisabled,
          orderID,
          selectedOption,
        }}
      />
    </div>
  );
};
